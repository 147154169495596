<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>Select Work Order</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-combobox
                        v-model="workOrder"
                        :items="workOrders"
                        item-text="code"
                        hide-details
                        prepend-icon="mdi-order-bool-ascending-variant"
                        placeholder="Select a WO *"
                    >
                    </v-combobox>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                class="mb-4"
                @click="addWO"
                rounded
                :loading="loading"
            >
                SELECT
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'SelectWO',
    props: {
        projectId: {
            type: String,
            default: () => '',
        },
    },
    data: () => ({
        loading: false,
        workOrder: undefined,
        workOrders: [],
    }),
    async mounted() {
        try {
            this.loading = true
            this.workOrders = await API.getWorkOrdersByProject({
                projectId: this.projectId,
                includeClosed: false,
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        addWO() {
            this.$emit('addWO', this.workOrder)
            this.$emit('openNewBomForm')
            this.close()
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
